var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isPC ? 'pc' : 'mobile',attrs:{"id":"app"}},[(_vm.isPC)?_c('nav',[_c('img',{staticClass:"pointer",attrs:{"src":require("./assets/logo.png"),"alt":""},on:{"click":() => _vm.$router.replace('pcHome')}}),_c('div',{staticClass:"flex"},[_c('router-link',{attrs:{"to":"/pcHome"}},[_vm._v("首页")]),_c('router-link',{attrs:{"to":{
					name: 'pcMeditation',
					query: { lesson: _vm.url === 'pcMeditation' ? _vm.lesson : 0 },
				}},nativeOn:{"click":function($event){return _vm.go.apply(null, arguments)}}},[_vm._v("正念冥想")]),_c('router-link',{attrs:{"to":{
					name: 'pcSound',
					query: { lesson: _vm.url === 'pcSound' ? _vm.lesson : 0 },
				}},nativeOn:{"click":function($event){return _vm.go.apply(null, arguments)}}},[_vm._v("舒缓声音")]),_c('router-link',{attrs:{"to":{
					name: 'pcStory',
					query: { lesson: _vm.url === 'pcStory' ? _vm.lesson : 0 },
				}},nativeOn:{"click":function($event){return _vm.go.apply(null, arguments)}}},[_vm._v("睡眠故事")])],1)]):_c('nav',[_c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("./assets/logo.png"),"alt":""},on:{"click":function($event){return _vm.toHome()}}}),_c('img',{staticClass:"nav-img",attrs:{"src":require("./assets/img/mobile/nav.png"),"alt":""},on:{"click":function($event){_vm.showNav = true}}})]),(_vm.showNav)?_c('div',{staticClass:"show-nav",on:{"click":function($event){_vm.showNav = false}}},[_c('div',{staticClass:"box",on:{"click":function($event){$event.stopPropagation();_vm.showNav = true}}},[_c('img',{staticClass:"close",attrs:{"src":require("./assets/img/mobile/close.png"),"alt":""},on:{"click":() => (_vm.showNav = false)}}),_c('img',{staticClass:"nav-img",attrs:{"src":require("./assets/img/mobile/nav-logo.png"),"alt":""},on:{"click":function($event){_vm.toHome();
						_vm.showNav = false;}}}),_c('div',{on:{"click":() => (_vm.showNav = false)}},[_c('router-link',{attrs:{"to":"/home"}},[_vm._v("首页")])],1),_c('div',{on:{"click":() => (_vm.showNav = false)}},[_c('router-link',{attrs:{"to":{
							name: 'meditation',
							query: { lesson: _vm.url === 'meditation' ? _vm.lesson : 0 },
						}},nativeOn:{"click":function($event){return _vm.go.apply(null, arguments)}}},[_vm._v("正念冥想")])],1),_c('div',{on:{"click":() => (_vm.showNav = false)}},[_c('router-link',{attrs:{"to":{
							name: 'sound',
							query: { lesson: _vm.url === 'sound' ? _vm.lesson : 0 },
						}},nativeOn:{"click":function($event){return _vm.go.apply(null, arguments)}}},[_vm._v("舒缓声音")])],1),_c('div',{on:{"click":() => (_vm.showNav = false)}},[_c('router-link',{attrs:{"to":{
							name: 'story',
							query: { lesson: _vm.url === 'story' ? _vm.lesson : 0 },
						}},nativeOn:{"click":function($event){return _vm.go.apply(null, arguments)}}},[_vm._v("睡眠故事")])],1)])]):_vm._e()]),_c('router-view'),(_vm.isPC)?_c('footer',[_c('div',{staticClass:"banner flex"},[_c('img',{staticClass:"bottom-logo",attrs:{"src":require("@/assets/img/pc/logo2.png"),"alt":""}}),_c('div',{staticClass:"right"},[_c('span',[_vm._v("产品")]),_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.$download(1)}}},[_vm._v("NiceDay for iPhone")]),_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.$download(2)}}},[_vm._v("NiceDay for Android")])])]),_vm._m(0)]):_c('footer',[_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/img/mobile/nav-logo.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("产品")]),_c('span',{on:{"click":function($event){return _vm.$download(0)}}},[_vm._v("NiceDay for iPhone")]),_c('span',{on:{"click":function($event){return _vm.$download(2)}}},[_vm._v("NiceDay for Android")])])]),_vm._m(1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_vm._v("重庆哎呦科技有限公司 "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index"}},[_vm._v("渝ICP备13001476号-2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_vm._v("重庆哎呦科技有限公司 "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index"}},[_vm._v("渝ICP备13001476号-2")])])
}]

export { render, staticRenderFns }