/*
 * @Author: DZZ
 * @Date: 2023-01-03 09:46:37
 * @LastEditTime: 2023-01-03 15:35:11
 * @LastEditors: DZZ
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/main.less'
import axios from './util/axios'

Vue.config.productionTip = false
Vue.prototype.$axios = axios

import util from '@/util/util'
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')