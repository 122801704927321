/*
 * @Author: DZZ
 * @Date: 2021-10-27 09:06:44
 * @LastEditTime: 2023-01-03 17:36:42
 * @LastEditors: DZZ
 * @Description: 
 */
import axios from "axios";
let testUrl = "http://niceday.youloft.com:8886/meditation/";
let proUrl = "https://niceday.youloft.com/meditation/";
let base = process.env.NODE_ENV === "production" ? proUrl : proUrl;
console.log('mode',process.env.NODE_ENV);
const service = axios.create({
  baseURL: base, // api 的 base_url
  timeout: 60000, // 超时时间
  headers: {
    // 'Content-Type':'application/x-www-form-urlencoded'
  },
});

service.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data);
  },
);

export default service;