/*
 * @Author: DZZ
 * @Date: 2023-01-03 09:46:37
 * @LastEditTime: 2023-01-03 14:52:10
 * @LastEditors: DZZ
 * @Description: 
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [ {
    path: '/pcHome',
    name: "pcHome",
    component: () =>
        import( /* webpackChunkName: "pcHome" */ '../views/pc/home.vue')
},
{
    path: '/pcMeditation',
    name: 'pcMeditation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "pcMeditation" */ '../views/pc/meditation.vue')
},{
    path: '/pcSound',
    name: 'pcSound',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "pcSound" */ '../views/pc/sound.vue')
},{
    path: '/pcStory',
    name: 'pcStory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "pcStory" */ '../views/pc/story.vue')
},{
    path: '/home',
    name: 'home',
    component: () =>
        import( /* webpackChunkName: "meditation" */ '../views/mobile/home.vue')
},
{
    path: '/meditation/:lession?',
    name: 'meditation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "meditation" */ '../views/mobile/meditation.vue')
},{
    path: '/sound',
    name: 'sound',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "sound" */ '../views/mobile/sound.vue')
},{
    path: '/story',
    name: 'story',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "story" */ '../views/mobile/story.vue')
}
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router