<template>
	<div id="app" :class="isPC ? 'pc' : 'mobile'">
		<nav v-if="isPC">
			<img src="./assets/logo.png" class="pointer" @click="() => $router.replace('pcHome')" alt="" />
			<div class="flex">
				<router-link to="/pcHome">首页</router-link>
				<router-link
					@click.native="go"
					:to="{
						name: 'pcMeditation',
						query: { lesson: url === 'pcMeditation' ? lesson : 0 },
					}"
					>正念冥想</router-link
				>
				<router-link
					@click.native="go"
					:to="{
						name: 'pcSound',
						query: { lesson: url === 'pcSound' ? lesson : 0 },
					}"
					>舒缓声音</router-link
				>
				<router-link
					@click.native="go"
					:to="{
						name: 'pcStory',
						query: { lesson: url === 'pcStory' ? lesson : 0 },
					}"
					>睡眠故事</router-link
				>
			</div>
		</nav>
		<nav v-else>
			<div class="header">
				<img src="./assets/logo.png" alt="" @click="toHome()" />
				<img src="./assets/img/mobile/nav.png" alt="" class="nav-img" @click="showNav = true" />
			</div>
			<div class="show-nav" v-if="showNav" @click="showNav = false">
				<div class="box" @click.stop="showNav = true">
					<img src="./assets/img/mobile/close.png" alt="" class="close" @click="() => (showNav = false)" />
					<img
						src="./assets/img/mobile/nav-logo.png"
						alt=""
						class="nav-img"
						@click="
							toHome();
							showNav = false;
						"
					/>

					<div @click="() => (showNav = false)">
						<router-link to="/home">首页</router-link>
					</div>
					<div @click="() => (showNav = false)">
						<router-link
							@click.native="go"
							:to="{
								name: 'meditation',
								query: { lesson: url === 'meditation' ? lesson : 0 },
							}"
							>正念冥想</router-link
						>
					</div>
					<div @click="() => (showNav = false)">
						<router-link
							@click.native="go"
							:to="{
								name: 'sound',
								query: { lesson: url === 'sound' ? lesson : 0 },
							}"
							>舒缓声音</router-link
						>
					</div>
					<div @click="() => (showNav = false)">
						<router-link
							@click.native="go"
							:to="{
								name: 'story',
								query: { lesson: url === 'story' ? lesson : 0 },
							}"
							>睡眠故事</router-link
						>
					</div>
				</div>
			</div>
		</nav>
		<router-view />
		<footer v-if="isPC">
			<div class="banner flex">
				<img src="@/assets/img/pc/logo2.png" class="bottom-logo" alt="" />
				<div class="right">
					<span>产品</span>
					<span @click="$download(1)" class="pointer">NiceDay for iPhone</span>
					<span @click="$download(2)" class="pointer">NiceDay for Android</span>
				</div>
			</div>
			<div class="bottom">重庆哎呦科技有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备13001476号-2</a></div>
		</footer>
		<footer v-else>
			<div class="banner">
				<img src="@/assets/img/mobile/nav-logo.png" alt="" />
				<div>
					<span>产品</span>
					<span @click="$download(0)">NiceDay for iPhone</span>
					<span @click="$download(2)">NiceDay for Android</span>
				</div>
			</div>
			<div class="bottom">重庆哎呦科技有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备13001476号-2</a></div>
		</footer>
	</div>
</template>
<script>
export default {
	name: 'app',
	data() {
		return {
			isPC: true,
			showNav: false,
			lesson: 0,
			url: '',
		};
	},
	created() {
		document.title = 'NiceDay';
	},
	mounted() {
		let w = window.innerWidth,
			isPC = w >= 1000;
		this.isPC = isPC;
		let url = location.hash.substr(2).split('?')[0],
			query = location.hash.split('=')[1];
		// url = url.split('/')[0];
		console.log('query', query);
		console.log('url', url, this.$route);
		this.lesson = query;
		this.url = url;
		// 首页：https://testmobile.51wnl-cq.com/niceday_office/#/home
		// 正在冥想：https://testmobile.51wnl-cq.com/niceday_office/#/meditation?lesson=0
		// 正在冥想详情页：https://testmobile.51wnl-cq.com/niceday_office/#/meditation?lesson=xx
		// 舒缓声音：https://testmobile.51wnl-cq.com/niceday_office/#/sound?lesson=0
		// 舒缓声音详情页：https://testmobile.51wnl-cq.com/niceday_office/#/sound?lesson=xx
		// 睡眠故事：https://testmobile.51wnl-cq.com/niceday_office/#/story?lesson=0
		// 睡眠故事详情页：https://testmobile.51wnl-cq.com/niceday_office/#/story?lesson=xx

		const urlObj = {
			pcHome: 'home',
			pcMeditation: 'meditation',
			pcSound: 'sound',
			pcStory: 'story',
			home: 'pcHome',
			meditation: 'pcMeditation',
			sound: 'pcSound',
			story: 'pcStory',
		};

		if (url === '') {
			if (this.isPC) {
				this.$router.replace({
					params: query ? { lesson: query } : {},
					path: 'pcHome',
				});
			} else {
				this.toHome();
			}
		} else if (url === 'meditation' || url === 'sound' || url === 'story') {
			if (this.isPC) {
				this.url = urlObj[url];
				this.$router.replace({ query: { lesson: query }, name: urlObj[url] });
			}
		}

		window.addEventListener('resize', () => {
			w = window.innerWidth;
			url = location.hash.substr(2).split('?')[0];
			query = location.hash.split('=')[1];
			isPC = w >= 1000;
			if (this.isPC != isPC) {
				console.log('resize', this.isPC, isPC, url);
				this.isPC = isPC;
				this.url = urlObj[url];
				this.$router.replace({
					query: query ? { lesson: query } : {},
					name: urlObj[url],
				});
			}
		});
	},
	methods: {
		toHome() {
			location.href = '#/home';
		},
		handleUrl(flag) {
			const w = window.innerWidth,
				query = location.hash.split('=')[1],
				urlObj = {
					pcHome: 'home',
					pcMeditation: 'meditation',
					pcSound: 'sound',
					pcStory: 'story',
					home: 'pcHome',
					meditation: 'pcMeditation',
					sound: 'pcSound',
					story: 'pcStory',
				},
				url = location.hash.substr(2).split('/')[0],
				isPC = w >= 1000;
			console.log('resize', this.isPC, isPC);
			if (this.isPC != isPC) {
				this.isPC = isPC;
				this.$router.replace({
					query: query ? { lesson: query } : {},
					path: urlObj[url],
				});
			}
		},
		go() {
			this.lesson = 0;
			window.scrollTo(0, 0);
			console.log('go', this.lesson);
		},
	},
};
</script>
<style lang="less" scoped>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	// color: #2c3e50;
}

nav {
	width: 100%;
	padding: 30px;
	position: fixed;
	background-color: #fff;
	top: 0;
	z-index: 10;
	// a {
	//   font-weight: bold;
	//   color: #2c3e50;
	//   padding: 15px 0;
	//   margin-left: 60px;
	//   text-decoration: none;
	//   &.router-link-exact-active {
	//     border-bottom: 3px solid #4c6bd1;
	//     text-decoration: none;
	//   }
	// }
}
.pc {
	padding-top: 130px;
	nav {
		height: 130px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 22%;
	}
	img {
		width: 120px;
	}
	a {
		font-weight: 500;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.8);
		text-decoration: none;
		padding-bottom: 15px;
		margin-right: 60px;
		border-bottom: 3px solid transparent;
		&:last-child {
			margin: 0;
		}
		&.router-link-exact-active {
			border-color: #4c6bd1;
		}
	}
	footer {
		.banner {
			width: 100%;
			height: 170px;
			padding: 0 22%;
			align-items: flex-start;
			justify-content: space-between;
			background: linear-gradient(to right, #f0fdf6 0%, #edf1fa 100%);
			padding-top: 30px;
			img {
				width: 60px;
			}
			.right {
				text-align: left;
			}
			span {
				font-size: 16px;
				font-family: Roboto-Light, Roboto;
				font-weight: 300;
				color: rgba(0, 0, 0, 0.6);
				display: block;
				line-height: 20px;
				&:first-child {
					color: #000000;
					font-weight: 600;
					font-size: 18px;
					margin-bottom: 30px;
				}
			}
		}
		.bottom {
			line-height: 112px;
			font-weight: 400;
			color: rgba(0, 0, 0, 0.4);
			font-size: 16px;
			a {
				text-decoration: underline !important;
				color: rgba(0, 0, 0, 0.4);
			}
		}
	}
}
.mobile {
	nav {
		height: 80px;
		width: 100vw;
		padding: 20px;
	}
	.header {
		display: flex;
		justify-content: space-between;
		text-align: center;
		img {
			width: 98.6px;
			height: 52px;
		}
		.nav-img {
			width: 24px;
			height: 24px;
			margin: auto 0;
		}
	}
	.show-nav {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		flex-direction: column;
		justify-content: center;
		.box {
			position: relative;
			width: 347px;
			height: 654px;
			border-radius: 16px;
			margin: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			background-color: #fff;
			align-items: center;
			.close {
				position: absolute;
				right: 17px;
				top: 17px;
				width: 20px;
				z-index: 1;
			}
			div {
				margin-top: 60px;
				a {
					font-size: 18px;
					font-family: Source Han Sans CN-Regular, Source Han Sans CN;
					font-weight: 400;
					color: rgba(0, 0, 0, 0.3);
					-webkit-background-clip: text;

					&.router-link-exact-active {
						border-color: transparent;
					}
				}
			}
		}
		.nav-img {
			border-radius: 15px;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
		}
		img {
			width: 66px;
			margin: 0 auto;
			margin-bottom: 40px;
		}
	}

	footer {
		.banner {
			width: 100%;
			height: 240px;
			background: linear-gradient(to right, #f0fdf6 0%, #edf1fa 100%);
			padding-left: 20px;
			padding-top: 30px;
			text-align: left;
			img {
				width: 43.95px;
				margin-bottom: 40px;
				border-radius: 15px;
				box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
			}
			span {
				font-size: 14px;
				font-family: Roboto-Light, Roboto;
				font-weight: 300;
				color: rgba(0, 0, 0, 0.6);
				display: block;
				line-height: 20px;
				&:first-child {
					color: #000000;
					font-weight: bold;
					font-size: 16px;
					line-height: 19px;
				}
				&:nth-child(2) {
					padding: 15px 0;
				}
			}
		}
		.bottom {
			line-height: 14px;
			font-weight: 400;
			color: rgba(0, 0, 0, 0.4);
			font-size: 12px;
			font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			text-align: left;
			padding-left: 20px;
			margin-top: 20px;
			margin-bottom: 53px;
			a {
				text-decoration: underline !important;
				color: rgba(0, 0, 0, 0.4);
			}
		}
	}
}
</style>
