/*
 * @Author: DZZ
 * @Date: 2023-01-03 09:46:37
 * @LastEditTime: 2023-01-03 16:33:01
 * @LastEditors: DZZ
 * @Description: 
 */
import Vue from 'vue'
Vue.prototype.$download = (type) => {
    const url = type === 1 ? 'https://apps.apple.com/cn/app/id1596038989' : type === 0 ? 'https://a.app.qq.com/o/simple.jsp?pkgname=com.youloft.niceday' : 'https://sj.qq.com/appdetail/com.youloft.niceday'


    window.location.href = url
}
Vue.prototype.$getClass = async function (class_type) {
    return await this.$axios.post('classicLesson', { class_type })
}
Vue.prototype.$getLesson = async function (id) {
    return await this.$axios.post('getLessonById', { id })
}
Vue.prototype.$getRandomArray = function (arr) {
    return arr.sort((x, y) => Math.random() > 0.5 ? -1 : 1).slice(0, 3)
}